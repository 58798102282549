import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
export default new VueRouter({
  routes: [{
    path: '/',
    name: 'index',
    component: () => import('@/pages/index.vue'),
    children: [{
        path: '/indexer',
        name: 'indexer',
        component: () => import('@/pages/indexer/indexer.vue'),
      }, {
        path: '/richtext2',
        name: 'richtext2',
        component: () => import('@/pages/indexer/richtext.vue'),
      },{
        path: '/richtext3',
        name: 'richtext3',
        component: () => import('@/pages/indexer/richtext2.vue'),
      }, {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login.vue'),
      }, {
        path: '/allMovie',
        name: 'allMovie',
        component: () => import('@/pages/indexer/allMovie.vue'),
      }, {
        path: '/search',
        name: 'search',
        component: () => import('@/pages/indexer/search.vue'),
      }, {
        path: '/filmdetails',
        name: 'filmdetails',
        component: () => import('@/pages/indexer/filmdetails.vue'),
      }, {
        path: '/Viewingrecords',
        name: 'Viewingrecords',
        component: () => import('@/pages/indexer/Viewingrecords.vue'),
      }, {
        path: '/creationList',
        name: 'creationList',
        component: () => import('@/pages/indexer/creationList.vue'),
      }, {
        path: '/creationdetails',
        name: 'creationdetails',
        component: () => import('@/pages/indexer/creationdetails.vue'),
      },
      {
        path: '/creationDetail2',
        name: 'creationDetail2',
        component: () => import('@/pages/indexer/creationDetail2.vue'),
      },
      {
        path: '/Mycreation',
        name: 'Mycreation',
        component: () => import('@/pages/indexer/Mycreation.vue'),
      }, {
        path: '/creationMy',
        name: 'creationMy',
        component: () => import('@/pages/indexer/creationMy.vue'),
      }, {
        path: '/upwork',
        name: 'upwork',
        component: () => import('@/pages/indexer/upwork.vue'),
      }, {
        path: '/news',
        name: 'news',
        component: () => import('@/pages/indexer/news.vue'),
      }, {
        path: '/my',
        name: 'my',
        component: () => import('@/pages/indexer/my.vue'),
        children: [{
          path: '/myIndex',
          name: 'myIndex',
          component: () => import('@/pages/indexer/my/myIndex.vue'),
        }, {
          path: '/myPurse',
          name: 'myPurse',
          component: () => import('@/pages/indexer/my/myPurse.vue'),
        }, {
          path: '/watchHistory',
          name: 'watchHistory',
          component: () => import('@/pages/indexer/my/watchHistory.vue'),
        }, {
          path: '/myFavorite',
          name: 'myFavorite',
          component: () => import('@/pages/indexer/my/myFavorite.vue'),
        }, {
          path: '/myDetail',
          name: 'myDetail',
          component: () => import('@/pages/indexer/my/myDetail.vue'),
        }, {
          path: '/withdrawdeposit',
          name: 'withdrawdeposit',
          component: () => import('@/pages/indexer/my/withdrawdeposit.vue'),
        }, {
          path: '/withdrawdepositDetail',
          name: 'withdrawdepositDetail',
          component: () => import('@/pages/indexer/my/withdrawdepositDetail.vue'),
        }, {
          path: '/topUp',
          name: 'topUp',
          component: () => import('@/pages/indexer/my/topUp.vue'),
        }, {
          path: '/Mypromotion',
          name: 'Mypromotion',
          component: () => import('@/pages/indexer/my/Mypromotion.vue'),
        }, {
          path: '/subordinate',
          name: 'subordinate',
          component: () => import('@/pages/indexer/my/subordinate.vue'),
        }, {
          path: '/inviteFriends',
          name: 'inviteFriends',
          component: () => import('@/pages/indexer/my/inviteFriends.vue'),
        }, {
          path: '/ApplicationPartnership',
          name: 'ApplicationPartnership',
          component: () => import('@/pages/indexer/my/ApplicationPartnership.vue'),
        }, {
          path: '/AboutUs',
          name: 'AboutUs',
          component: () => import('@/pages/indexer/my/AboutUs.vue'),
        }, {
          path: '/mykf',
          name: 'mykf',
          component: () => import('@/pages/indexer/my/mykf.vue'),
        }, {
          path: '/set',
          name: 'set',
          component: () => import('@/pages/indexer/my/set.vue'),
        }, {
          path: '/serviceAgreement',
          name: 'serviceAgreement',
          component: () => import('@/pages/indexer/my/serviceAgreement.vue'),
        }, {
          path: '/myfans',
          name: 'myfans',
          component: () => import('@/pages/indexer/my/myfans.vue'),
        }, {
          path: '/richtext',
          name: 'richtext',
          component: () => import('@/pages/indexer/my/richtext.vue'),
        }, {
          path: '/cardbinding',
          name: 'cardbinding',
          component: () => import('@/pages/indexer/my/cardbinding.vue'),
        }, {
          path: '/cardList',
          name: 'cardList',
          component: () => import('@/pages/indexer/my/cardList.vue'),
        }, {
          path: '/cardbinding2',
          name: 'cardbinding2',
          component: () => import('@/pages/indexer/my/cardbinding2.vue'),
        }, {
          path: '/xiaofeiqindan',
          name: 'xiaofeiqindan',
          component: () => import('@/pages/indexer/my/xiaofeiqindan.vue'),
        }, {
          path: '/choujiangquan',
          name: 'choujiangquan',
          component: () => import('@/pages/indexer/my/choujiangquan.vue'),
        }, {
          path: '/mytaocan',
          name: 'mytaocan',
          component: () => import('@/pages/indexer/my/mytaocan.vue'),
        }, ]
      },
    ]
  }]
})
// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })
//解决路由重复点击问题
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// export default router